<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Kullanıcılar</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_KullanıcıModal()" />
        </div>
        <DataTable v-if="DataTable_render" :columns="columns" :data="KullanicilarListesi"
          v-on:set-child-data="getDataChild" />
      </template>
    </Card>

    <Dialog v-model:visible="KullanıcıModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Yeni Kullanıcı</h4>
      </template>

        <div class="p-grid p-fluid p-formgrid">
          <div class="p-field p-col-6">
            <span for="spanAd">Kullanıcı Adı</span>
            <InputText type="text" v-model="KullaniciAd" aria-labelledby="spanKullaniciAdi" />
          </div>
          <div class="p-field p-col-6">
            <span for="spanAd">Ad</span>
            <InputText type="text" v-model="Ad" aria-labelledby="spanAd" />
          </div>
          <div class="p-field p-col-6">
            <span for="spanSoyad">Soyad</span>
            <InputText type="text" v-model="Soyad" aria-labelledby="spanSoyad" />
          </div>
          <div class="p-field p-col-6">
            <span for="spanSoyad">Rol</span>
            <Dropdown id="drpRol" v-model="SelectedRol" :options="RolListesi" optionLabel="Ad" optionValue="Rol_rowid"
              placeholder="Seçiniz..." />
          </div>
          <div class="p-field p-col-6">
            <span for="spanSirket">Şirket</span>
            <Dropdown id="drpSirket" v-model="SelectedSirket" :options="SirketListesi" optionLabel="Unvan" optionValue="Sirket_rowid"
              placeholder="Seçiniz..." />
          </div>
          <div class="p-field p-col-6">
                        <span for="spanUnvan">Ünvan</span>
                        <InputText type="text" v-model="Unvan" aria-labelledby="spanUnvan" />
                    </div>
          <div class="p-field p-col-6">
            <span for="spanSifre">Sifre</span>
            <Password v-model="Sifre" aria-labelledby="spanSifre">
              <template #header>
                <h6>Şifre Seçin</h6>
              </template>
              <template #footer>
                <Divider />
                <p class="mt-2">Kurallar</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>Şuan herşey serbest</li>
                </ul>
              </template>
            </Password>
          </div>
        </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success" @click="this.handleClick()"
          autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._KullanıcıModal()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";

export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      DataTable_render: true,
      KullanicilarListesi: [],
      RolListesi: [],
      SirketListesi: [],
      KullanıcıModal: false,
      KullanıcıPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Kullanici, False = Edit Kullanıcı
      columns: [
        { field: "Ad", header: "Kullanıcı Adi" },
        { field: "OlusturmaTarih", header: "Oluşturma Tarihi" },
      ],
      KullaniciAd: null,
      Ad: null,
      Soyad: null,
      SelectedRol: null,
      Sifre: null,
      Kullanici_rowid: null,
      SelectedSirket: null,
      Unvan: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.KullanıcıPopUpDurumu = false;
      this.KullanıcıModal = !this.KullanıcıModal;
      this.Kullanici_rowid = getdata_child.data.Kullanici_rowid;
      let data = {
        Kullanici_rowid: getdata_child.data.Kullanici_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetKullaniciInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.Unvan = res.data[0].Unvan;
            this.KullaniciAd = res.data[0].KullaniciAd;
            this.Ad = res.data[0].Ad;
            this.Soyad = res.data[0].Soyad;
            this.SelectedRol = res.data[0].pRol;
            this.SelectedSirket = res.data[0].pSirket;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    handleClick() {
        let data = {
          Kullanici_rowid: this.Kullanici_rowid,
          KullaniciAd: this.KullaniciAd,
          Ad: this.Ad,
          Soyad: this.Soyad,
          pRol: this.SelectedRol,
          pSirket: this.SelectedSirket,
          Sifre: this.Sifre,
          Unvan: this.Unvan,
        };
        if (this.KullanıcıPopUpDurumu) {
          // Yeni Kullanıcı
          GlobalServis.GlobalServis("POST", "SetKullanici", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Kullanıcı oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._KullanıcıModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          // Edit Kullanıcı
          GlobalServis.GlobalServis("POST", "UpdateKullanici", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Kullanıcı düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this._KullanıcıModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
     
    },
    _KullanıcıModal() {
      this.KullanıcıPopUpDurumu = true;
      this.KullanıcıModal = !this.KullanıcıModal;
      if (!this.KullanıcıModal) {
        this.resetForm();
      }
    },
    resetForm() {
      // this.v$.$reset;
      (this.KullaniciAd = null),
        (this.Ad = null),
        (this.Soyad = null),
        (this.SelectedRol = null),
        (this.Sifre = null);
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      this._GetKullaniciListesi();
      this._GetRolListesi();
      this._GetSirketListesi();
    },
    _GetKullaniciListesi(){
      GlobalServis.GlobalServis("GET", "GetKullaniciTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.KullanicilarListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
    _GetRolListesi(){
      GlobalServis.GlobalServis("GET", "GetRol", "").then((res) => {
          if (res.status == 200) {
            this.RolListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        });
    },
    _GetSirketListesi(){
      GlobalServis.GlobalServis("GET", "GetSirket", "").then((res) => {
          if (res.status == 200) {
            this.SirketListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        });
    },
    onResize() {
      this.windowHeight = window.innerHeight
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.InitServis();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  components: {
    DataTable,
  }
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
